// import React from 'react'
// import { MDXProvider } from '@mdx-js/react'
// import { Code } from './src/components/code'
// import { preToCodeBlock } from 'mdx-utils'

// // components is its own object outside of render so that the references to
// // components are stable
// const components = {
//   pre: preProps => {
//     const props = preToCodeBlock(preProps)
//     // if there's a codeString and some props, we passed the test
//     if (props) {
//       return <Code {...props} />
//     } else {
//       // it's possible to have a pre without a code in it
//       return <pre {...preProps} />
//     }
//   },
// }
// export const wrapRootElement = ({ element }) => (
//   <MDXProvider components={components}>{element}</MDXProvider>
// )
import { MDXProvider } from '@mdx-js/react';
import Code from './src/components/Code';
import React from 'react';
import Bio from './src/components/Bio';

const components = {
    pre: ({ children: { props } }) => {
        if (props.mdxType === 'code') {
          return (
            <Code
              codeString={props.children.trim()}
              language={
                props.className && props.className.replace('language-', '')
              }
              {...props}
            />
          );
        }
      },
    }

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);